const load_animations = async () => {
  await import('~/javascripts/slide_reveal');
  await import('~/javascripts/animations/home');
  await import('~/javascripts/home_ambassadors');
  await import('~/javascripts/home_social');
};

const trigger = document.getElementById('header-scroll-observer');
const triggerObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        return;
      }

      load_animations();

      triggerObserver.unobserve(trigger);
    });
  },
  {
    rootMargin: '80px',
  }
);

triggerObserver.observe(trigger);
